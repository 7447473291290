/* Modal.css */
.modal-backdrop {
  z-index: 9999; /* Достаточно высокое значение для отображения поверх всех элементов */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}



.modal-content {
  background-color: #212121;
  color: #B8B1B1;
  padding: 20px;
  border-radius: 8px;
  width: auto; /* Убрано фиксированное значение, начальная ширина теперь задается min-width */
  min-width: 300px; /* Минимальная ширина окна */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1051;
  overflow: auto; /* Позволяет скроллить внутри, если контент больше окна */
}


.modal-close-btn {
  float: right;
  background: #BCFD49;
  border: none;
  color: black;
  padding: 8px 16px;
  margin: -20px -20px 20px 0;
  border-radius: 0 8px 0 8px;
  cursor: pointer;
}

.email-details {
  margin-bottom: 20px;
  
}

.email-body {
  max-height: 600px;
  overflow-y: auto;
}
