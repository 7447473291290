.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #171717;
  }
  
  .main-heading {
    font-size: 24px; /* Размер шрифта для заголовка */
    color: #333; /* Цвет текста */
  }
  
  .main-text {
    font-size: 16px; /* Размер шрифта для основного текста */
    color: #666; /* Цвет текста */
  }
  