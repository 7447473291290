.gmail-window {
  position: relative;
  width: 250px;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  color: white;
  background-color: #212121; /* Светло-черный фон */
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow-y: auto; /* Добавление прокрутки */
  padding: 20px;
}

.vertical-line {
  position: absolute;
  right: 50px; /* Отступ от правого края окна */
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #555;
}

.icons-container {
  position: absolute;
  right: 15px; /* Отступ от правого края окна */
  top: 90px; /* Отступ сверху */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  background-color: none;
  width: 22px;
  height: 22px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white; /* Цвет текста */
}

.email-item {
  width: 180px;
  height: 100px;
  background-color: #171717; /* Темно-серый фон для элементов */
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px; /* Скругление углов */
  box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Тень */
  overflow: hidden;
  white-space: normal; 
}

.email-header, .email-subject, .email-snippet {
  margin-bottom: 5px;
}

.email-header {
  color: #e0e0e0;
  font-size: 13px;
}

.email-subject {
  color: #BCFD49;
  font-size: 12px;
}

.email-snippet {
  color: #B8B1B1;
  font-size: 11px;
}

.drive-item {
  width: 160px;
  background-color: #171717; /* Светло-серый фон */
  border-radius: 8px; /* Закругленные углы */
  padding: 10px 20px; /* Отступы внутри элемента */
  margin: 10px 0; /* Отступы между элементами */
  font-size: 11px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Тень */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  overflow: auto; /* Скрывает содержимое, выходящее за рамки */
  text-overflow: ellipsis;
  white-space: normal; 
  cursor: pointer;
}

.drive-item:hover {
  transform: scale(1.05); /* Увеличение при наведении */
  box-shadow: 0 5px 15px rgba(0,0,0,0.2); /* Усиленная тень при наведении */
}

.drive-item .document-name {
  color: #BCFD49; /* Цвет названия документа */
  font-size: 12px; /* Дополнительные стили, если необходимо */
}

.drive-item .document-type {
  color: #B8B1B1; /* Цвет типа документа */
  font-size: 11px; /* Дополнительные стили, если необходимо */
}

.notion-auth-button {
  background-color: #BCFD49;
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 14px;
}

.notion-auth-button:hover {
  background-color: #A4E928;
}
