.sidebar {
    width: 180px;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #212121; /* Light background */
    color: white;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 2;
}

.sidebar h2 {
    font-size: 1.2em;
    color: #BCFD49;
    margin-bottom: 30px;
    font-size: 15px;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 50px;
}

.sidebar li {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    font-size: 1em;
    color: white;
    cursor: pointer;
}

.sidebar li:hover {
    background-color: #f0f0f0;
    border-radius: 4px;
}

.fa-icon {
    margin-right: 10px;
    color: #888;
}

.projects {
    margin-bottom: 20px;
}

.logout-button {
    background: none;
    border: none;
    color: #BCFD49;
    cursor: pointer;
    font-size: 1em;
    padding: 10px 0;
    display: flex;
    align-items: center;
}

.logout-button:hover {
    background-color: #f0f0f0;
    border-radius: 4px;
    color: #c9302c;
}
