.weekdays-container {
    display: flex;
    overflow-x: scroll; /* Позволяет горизонтальную прокрутку */
    white-space: nowrap;
    padding: 10px;
    position: fixed;
    top: 50px;  /* Верх экрана */
    left: 200px; 
    right: 250px; 
    background-color: transparent;
    z-index: 1;
}

/* Скрываем горизонтальную полосу прокрутки */
.weekdays-container::-webkit-scrollbar {
    display: none;
}

.day {
    display: inline-block; /* Обеспечивает горизонтальное выравнивание */
    margin: 0 50px; /* Расстояние между днями */
    text-align: left; /* Выровнять текст слева */
  }
  
  .day-name, .day-date {
    text-align: left; /* Выровнять текст слева */
    padding-left: 10px; /* Отступ слева */
  }
  
  .day-name {
    font-size: 30px;
    font-weight: bold;
    color: white;
  }
  
  .day-date {
    font-size: 20px;
    margin-top: 25px;
    color: white;
  }
  
.rectangle {
    width: 170px; /* Фиксированная ширина */
    height: 15px; /* Фиксированная высота */
    margin: 5px 0;
    padding: 10px;
    margin-top: 20px;
    background-color: #212121;
    color: #AFA9A9;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.task-rectangle {
    width: 220px;
    min-height: 20px; 
    padding: 10px;
    margin: 5px 0 20px;
    background-color: #f0f0f0;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    overflow: auto;  /* Позволяет добавить прокрутку при необходимости */
    white-space: normal; /* Позволяет тексту переноситься на новые строки */
    text-overflow: clip; /* Убирает многоточие и просто обрезает текст */
    height: auto; 
  }

.weekdays-container .email-item {
    width: 170px; 
    height: 100px; 
    background-color: #212121; 
    color: white;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
    border-radius: 8px; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
    overflow: auto;
    text-overflow: ellipsis;
    white-space: normal; 
}





