.taskmodal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Полупрозрачный черный фон */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Убедитесь, что модальное окно находится над другими элементами */
  }
  
  .taskmodal-content {
    background-color: white; /* Белый фон для модального окна */
    padding: 20px;
    border-radius: 8px; /* Скругленные углы */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Тень для выделения модального окна */
    width: 90%; /* Ширина модального окна относительно экрана */
    max-width: 500px; /* Максимальная ширина модального окна */
  }
  
  .taskmodal-close-btn {
    cursor: pointer;
    background: red;
    color: white;
    border: none;
    padding: 8px 16px;
    font-size: 16px;
    float: right;
    margin: -16px -16px 16px 16px; /* Отступы для кнопки закрытия */
    border-radius: 4px; /* Скругленные углы для кнопки */
  }
  
  .input-group {
    margin-bottom: 16px; /* Добавляет отступ между группами ввода */
  }
  
  .input-label {
    display: block; /* Заставляет метку занимать всю ширину, текст начинается с новой строки */
    margin-bottom: 8px; /* Отступ от метки до поля ввода */
    font-weight: bold; /* Делает текст метки жирным */
  }
  
  input[type="text"],
  input[type="date"],
  input[type="time"] {
    width: 100%; /* Полная ширина внутри модального окна */
    padding: 8px;
    border: 1px solid #ccc; /* Граница */
    border-radius: 4px; /* Скругленные углы для полей ввода */
  }
  
  button[type="submit"] {
    background-color: #4CAF50; /* Зеленый фон для кнопки отправки */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px; /* Скругленные углы для кнопки */
    cursor: pointer;
    margin-top: 12px; /* Отступ сверху */
    width: 100%; /* Ширина кнопки */
  }
  
  button[type="submit"]:hover {
    background-color: #45a049; /* Темнее при наведении */
  }
  
  